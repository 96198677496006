.swiperWrapper {
  padding: 0px 80px;
}

.header {
  position: relative;
  color: white;
  background: linear-gradient(135deg, #62c2ec 0%, #da9500 100%);
}

@media screen and (max-width: 800px) {
  .swiperWrapper {
    padding: 0px;
  }
}
